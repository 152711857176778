// change styles

.change-password-container {
  margin-top: 80px;

  .change-password {
    width: 30%;
    margin: 0px auto;
    padding: 50px 0px;

    .change {
      &-contents {
        padding: 20px 10px;
      }

      &-head {
        font-family: $avertaBold;
        font-size: $heading_one;
        color: $defaulttextcolor;
        margin-bottom: 20px;
      }

      &-fields {
        margin-bottom: 20px;

        &-title {
          font-family: $avertaSemiBold;
          font-size: $text;
          color: $defaulttextcolor;
          padding-bottom: 8px;
        }
      }

      &-action {
        width: 100%;
      }
    }
  }
}
