// Color pallete
$white: #ffffff;
$black: #000000;
$teal: #3180a9;
$orange: #ff8144;
$green: #5b9540;
$purple: #a259f4;
$red: #ff4343;
$blue: #2480f5;

// Black variants
$black100: #13293d;
$black200: #484451;

// Grey variants
$grey100: #a1a1a1;
$grey200: #e1e1e1;
$grey300: #f1f1f1;
$grey400: #f8f8f8;

// Primary variants
$primary100: #5d32aa;
$primary200: #8860d0;
$primary300: #a99fbb;
$primary400: #f5f0ff;
$primary500: #fcfaff;

// TEXT COLORS
$defaulttextcolor: #555555;
$lighttextcolor: #707070;
$secondarytextcolor: #989898;

// BORDER COLORS
$defaultborder: #e1e1e1;

// FONT FAMILIES
$avertaLight: AvertaLight;
$avertaSemiBold: AvertaSemiBold;
$avertaBold: AvertaBold;
$avertaExtraBold: AvertaExtraBold;

$default_radius: 8px;

$large_heading: 32px;
$heading_one: 24px; // Bold
$heading_two: 20px; // Bold
$text: 16px; // Medium for Links/Text/SubHeaing, Regular
// FONT SIZES
// DEFAULT => 16px
// 12px => 0.75rem
// 20px => 1.25rem
// 24px => 1.5rem
// 28px => 1.75rem
// 32px => 2rem
// 38px => 2.375rem
// 80px => 5rem
$extralarge: 3.5rem;
$large: 2.375rem;
$medium: 2rem;
$small: 1.75rem;
$extrasmall: 1.5rem;
$thin: 1.25rem;
$extrathin: 1rem;
$hairline: 0.75rem;

// WIDTH
$default_width: 85%;
