//TOOLTIP COMMON
.tooltip-wrapper {
  position: relative;
  .tooltip-text {
    visibility: hidden;
    position: absolute;
    background-color: $black;
    padding: 2px 8px;
    border-radius: 5px;
    color: $white;
    text-align: center;
    position: absolute;
    z-index: 1;
    white-space: nowrap;
    align-self: center;
  }
}

.tooltip-wrapper:hover .tooltip-text {
  visibility: visible;
  font-size: $hairline;
}
