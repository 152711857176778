.checkbox-container {
  .checkbox {
    display: flex;
    cursor: pointer;
    flex-direction: row;
    column-gap: 10px;

    &-label {
      margin-left: 10px;
      cursor: pointer;
      white-space: nowrap;
    }
  }
}
