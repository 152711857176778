table.keyvalue-table-container {
  border: 1px solid $grey200;
  border-collapse: collapse;
  width: 100%;
  $padding: 8px;
  $fontSize: 14px;
  // width: 100%;

  &.editable {
    border: 1px solid $primary100;
    flex: 1;
  }
  tbody {
    tr {
      border-bottom: 1px solid $grey200;

      td {
        padding: $padding;
        min-width: 160px;
        // max-width: 280px;

        &:first-child {
          font-weight: bold;
          border-right: 1px solid $grey200;
          font-size: $fontSize;
        }

        &:nth-child(2) {
          // padding: $padding;
          padding: 0px;
          position: relative;

          input,
          select {
            padding: $padding;
            font-size: $fontSize;
            border: none;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            background: $white;
          }

          div {
            padding: $padding;
            color: $black200;
            font-size: $fontSize;
          }
        }
      }

      &:nth-child(even) {
        background-color: $white;
      }

      &:nth-child(odd) {
        background-color: $grey300;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}
