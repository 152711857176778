.button {
  outline: none;
  border: none;
  padding: 12px 16px;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  color: $black100;

  &.default {
    background: $white;
    border: 1px solid $grey200;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);

    &:hover {
      background: $grey300;
    }
  }

  &.icon {
    background: transparent;
  }

  &.ripple {
    background-color: #fff;
    text-align: center;
    border-radius: 4px;
    border: 1px solid #e1e1e1;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    position: relative;
    overflow: hidden;
    user-select: none;
    transition: background 0.1s, box-shadow 0.1s;
    white-space: nowrap;
    align-items: center;

    &:hover {
      background: #f8f8f8;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    }

    &:active {
      background: #f1f1f1;
    }

    .rippleoverlay {
      position: absolute;
      width: 100px;
      height: 100px;
      left: 9px;
      top: -25px;
      background: rgba(0, 0, 0, 0.1);
      border-radius: 50%;
    }
  }
}
